import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "../Icard/Icard.css";

const Icard = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    age: "",
    selected_date: "",
    selected_time: "",
    concern: "",
    
  });
  

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    age: "",
    concern: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = /^[A-Za-z\s]+$/.test(value)
          ? ""
          : "Name should contain only alphabets.";
        break;
      case "email":
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address.";
        break;
      case "mobile":
        errors.mobile = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number should be 10 digits.";
        break;
      case "age":
        errors.age = value >= 15 && value <= 70
          ? ""
          : "Age should be between 15 and 70.";
        break;
      case "concern":
        errors.concern = value.length >= 15
          ? ""
          : "Concern should be at least 15 characters long.";
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validate(name, value); // Validate input as the user types
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(formErrors).some(error => error !== "")) {
      setIsSubmitting(true); // Disable the submit button
      
   
      emailjs.send('service_m6adkcy', 'template_o3n51ef', formData, 'FE_qmfU7NpslQTD_b')
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Celebrating Our Launch!',
          text: 'Thanks for joining us! Enjoy a special 5% discount on your first purchase!',
        });
        onClose(); // Close modal after submission
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to send message. Please try again later.',
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Enable submit button again
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Form Incomplete',
        text: 'Please fix the errors in the form before submitting.',
      });
    }
  };

  return (
    <div className="modal5">
      <div className="modal-content5">
        {/* Close Button (Cross Sign) */}
        <span className="close-button5" onClick={onClose}>
          &times;
        </span>
        <div className="icard-container">
          <div className="left-icard">
            <img src="/pop1.jpeg" alt="Icard" className="icard-image" />
          </div>
          <div className="right-icard">
            <h2>Fill out the consultation form</h2>
            <form onSubmit={handleSubmit} className="icard-form">
              <div className="input-pair">
                <div className="fff">
                  <input
                    type="text1"
                    name="name"
                    placeholder="Name"
                    className="input-name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.name && (
                    <span className="error-message3">{formErrors.name}</span>
                  )}
                </div>
                <div className="fff">
                  <input
                    type="email1"
                    name="email"
                    placeholder="Email"
                    className="input-email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.email && (
                    <span className="error-message3">{formErrors.email}</span>
                  )}
                </div>
                <div className="fff">
                  <input
                    type="text1"
                    name="mobile"
                    placeholder="Mobile"
                    className="input-mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.mobile && (
                    <span className="error-message3">{formErrors.mobile}</span>
                  )}
                </div>
              </div>
              <div className="input-pair">
                <div className="fff">
                  <input
                    type="number1"
                    name="age"
                    placeholder="Age"
                    className="input-age"
                    value={formData.age}
                    onChange={handleChange}
                    required
                  />
                  {formErrors.age && (
                    <span className="error-message3">{formErrors.age}</span>
                  )}
                </div>
                <div className="fff">
                  <input
                    type="date"
                    name="selected_date"
                    className="input-date"
                    value={formData.selected_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="fff">
                 <select
                    name="selected_time"
                    className="input-time2"
                    value={formData.selected_time}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a time</option>
                    <option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</option>
                    <option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</option>
                    <option value="12:00 PM - 1:00 PM">12:00 PM - 1:00 PM</option>
                    <option value="1:00 PM - 2:00 PM">1:00 PM - 2:00 PM</option>
                    <option value="2:00 PM - 3:00 PM">2:00 PM - 3:00 PM</option>
                    <option value="3:00 PM - 4:00 PM">3:00 PM - 4:00 PM</option>
                  </select>
                </div>
              </div>
              <div className="fff">
                <textarea
                  name="concern"
                  placeholder="Your Concern"
                  className="input-message"
                  value={formData.concern}
                  onChange={handleChange}
                  required
                ></textarea>
                {formErrors.concern && (
                  <span className="error-message3">{formErrors.concern}</span>
                )}
              </div>
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting} // Disable button if submitting
              >
                {isSubmitting ? "Sending..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icard;
