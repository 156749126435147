import React, { useState } from "react";
import ProductCard from "../Products/ProductCard";
import "../Products/Products.css";

const Product = ({ selectedCategory }) => {
  const [visibleProducts, setVisibleProducts] = useState(6);
  const defaultCleanserCategory = "Cleansing"; 

  const showMoreProducts = () => {
    setVisibleProducts(visibleProducts + 3);
  };

  const allProducts = [
    
    {
      id: 1,
      title: "Pink Rose Cleansing Balm",
      description: "Discover our gentle Pink Rose Cleansing Balm, a 3-in-1 oil-based cleanser that transforms from balm to milk. Infused with Kaolin, Calamine, and French Pink clay for a natural skin detox. Experience clean, soft, and refreshed skin today.",
      price: 700,
      size: "45gm",
      rating: 5,
      reviews: 120,
      emi: "₹533/Month | 0% EMI",
      image: "pinkt.png",
      category: "Cleansing",
    },
    {
      id: 2,
      title: "Pure Cleanse Micellar Water",
      description: "Discover Pure Cleanse Micellar Water, a gentle yet effective cleanser enriched with natural extracts. This 4-in-1 solution cleanses, removes makeup, tones, and hydrates, suitable for all skin types.",
      price: 750,
      size: "100 ml",
      rating: 5,
      reviews: 85,
      emi: "₹733/Month | 0% EMI",
      image: "puret.png",
      category: "Cleansing",
    },
    {
      id: 3,
      title: "Natural Powder Facewash",
      description: "Experience the gentle cleansing power of our natural powder facewash. Made with Kaolin Clay, French Blue Clay, and soothing oatmeal, this face wash leaves your skin clean, refreshed, and nourished.",
      price: 199,
      size: "15 gm",
      rating: 4.6,
      reviews: 150,
      emi: "₹400/Month | 0% EMI",
      image: "naturalcover.jpg",
      category: ["Cleansing","Exfoliators","Mask"],
    },
    {
      id: 4,
      title: "Green Tree Cleansing Balm",
      description: "Discover the power of nature with our Green Tree Cleansing Balm. This gentle, oil based cleanser combines clay and herbal infusions to detoxify, nourish, and revitalize your skin. Experience cleaner, softer skin today.",
      price: 700,
      size: "45 gm",
      rating: 4.9,
      reviews: 85,
      emi: "₹467/Month | 0% EMI",
      image: "gtcover.jpg",
      category: "Cleansing",
    },
    {
      id: 5,
      title: "Oat Bloom Moisturizer",
      description: "Discover the power of oat protein and botanical ingredients in our Oat Bloom Moisturizer. It is a multifunctional skincare product designed to hydrate, brighten, and soothe the skin. It Protects skin from free radical damage and boosts collagen production for firmer skin.",
      price: 1800,
      size: "50 gm",
      rating: 5,
      reviews: 120,
      emi: "₹600/Month | 0% EMI",
      image: "bloomcover.jpg",
      category: "Moisturizers",
    },
    {
      id: 6,
      title: "Blue Moon Moisturizer",
      description: "Blue Moon Moisturizer combines the power of natural ingredients to deeply hydrate and rejuvenate your skin. Infused with Neroli Hydrosol and Blueberry Extract, this moisturizer provides a refreshing boost of moisture while helping to improve your skin's overall appearance.",
      price: 1850,
      size: "50gm",
      rating: 5,
      reviews: 95,
      emi: "₹667/Month | 0% EMI",
      image: "mooncover.jpg",
      category: "Moisturizers",
    },
    {
      id: 7,
      title: "Azeloyl Skin Renewal Moisturizer",
      description: "Experience the power of natural ingredients and advanced skincare technology with our Azeloyl Skin Renewal Moisturizer. Hydrate, brighten, and renew your skin for a radiant, youthful complexion.",
      price: 1850,
      size: "50gm",
      rating: 5,
      reviews: 95,
      emi: "₹433/Month | 0% EMI",
      image: "azeloylcover.jpg",
      category: "Moisturizers",
    },
    {
      id: 8,
      title: "Cherry Berry Moisturizer",
      description: "Our Cherry Berry Moisturizer is formulated with Rose Hydrosol and Jojoba Oil, known for their excellent moisturizing properties. These ingredients work together to lock in moisture, leaving your skin feeling fresh and hydrated throughout the day. ",
      price: 1350,
      size: "50gm",
      rating: 4.8,
      reviews: 80,
      emi: "₹833/Month | 0% EMI",
      image: "cherrycover.jpg",
      category: "Moisturizer",
    },
    {
      id: 9,
      title: "Papaya Scrub with Papain",
      description: "Experience gentle exfoliation and deep hydration with our natural papain facial scrub. This facial scrub provides deep hydration while cleansing. The combination of xylitol and papaya hydrosol enzymes helps maintain skin moisture balance.",
      price: 650,
      size: "30gm",
      rating: 4.7,
      reviews: 65,
      emi: "₹367/Month | 0% EMI",
      image: "papayacover.jpg",
      category: "Exfoliators",
    },
    {
      id: 10,
      title: "AHA+BHA+PHA Facial Scrub",
      description: "Our AHA, BHA, PHA Facial Scrub combines three powerful exfoliating agents to reveal smoother, brighter skin. The blend of alpha- hydroxy acids (AHA), beta- hydroxy acid (BHA), and poly- hydroxy acid (PHA) work together to remove dead skin cells.",
      price: 800,
      size: "30gm",

      price2: 1300,
      size2: "50gm",
      rating: 5,
      reviews: 72,
      emi: "₹633/Month | 0% EMI",
      image: "ahacover.jpg",
      category: "Exfoliators",
    },
    {
      id: 11,
      title: "Chocolate Clay Mask",
      description: "The combination of Chocolate Clay, Calamine Clay and Kaolin Clay absorbs excess oil, draws out impurities, and detoxifies the skin, leaving it refreshed and clear. It includes Magnesium Ascorbyl Phosphate, a stable form of vitamin C.",
      price: 750,
      size: "30gm",

      price2: 1250,
      size2: "50gm",
      rating: 5,
      reviews: 85,
      emi: "₹433/Month | 0% EMI",
      image: "chocolatecover.jpg",
      category: "Mask",
    },
    {
      id: 12,
      title: "Sparkle Eyes Revitalizing Eye Cream",
      description: "Nourish and brighten your delicate eye area with Sparkle Eyes Revitalizing Eye Cream. Formulated with natural ingredients to hydrate, reduce dark circles, and combat signs of aging. Suitable for all skin types.",
      price: 1800,
      size: "15ml",
      rating: 5,
      reviews: 90,
      emi: "₹767/Month | 0% EMI",
      image: "sparklecover.jpg",
      category: "Eye",
    },
    {
      id: 13,
      title: "Magnesium Ascorbyl Phosphate + Tranexamic Face Serum",
      description: "Discover our gentle yet effective face serum combining stable Vitamin C Magnesium Ascorbyl Phosphate (MAP), Tranexamic Acid, and Niacinamide. Address dark spots, uneven tone, and support collagen for brighter.",
      price: 1350,
      size: "30ml",
      rating: 4.8,
      reviews: 65,
      emi: "₹500/Month | 0% EMI",
      image: "mapcover.jpg",
      category: "Serums",
    },
    {
      id: 14,
      title: "Mandelic Acid + Salicylic Acid Face Serum",
      description: "This face serum combines the power of mandelic acid (AHA) and salicylic acid (BHA) to gently exfoliate your skin. These ingredients work together to unclog pores, remove dead skin cells, and promote a smoother complexion.",
      price: 1350,
      size: "30ml",
      rating: 5,
      reviews: 72,
      emi: "₹533/Month | 0% EMI",
      image: "mandeliccover.jpg",
      category: "Serums",
    },
    {
      id: 15,
      title: "Clarity Complex Corrector",
      description: "Our Clarity Complex Corrector uses cutting-edge skincare technology to deliver real results. The combination of Polyglyceryl-3 Dicitrate/Stearate and Sodium Palmitoyl Proline-Nymphaea Alba Flower Extract helps to smooth dark spots and even out skin tone.",
      price: 1450,
      size: "15ml",

      price2: 2550,
      size2: "30ml",
      rating: 5,
      reviews: 85,
      emi: "₹600/Month | 0% EMI",
      image: "claritycover.jpg",
      category: "Spot Corrector",
    },
    {
      id: 16,
      title: "Night Bliss Restorative Cream",
      description: "Our Night Bliss Restorative Cream combines nature's finest ingredients to rejuvenate your skin while you sleep.It is designed to address hyperpigmentation concerns, helping you achieve a clearer and more luminous skin tone over time.",
      price: 2250,
      size: "50gm",

      price2: 1650,
      size2: "30gm",
      rating: 5,
      reviews: 90,
      emi: "₹633/Month | 0% EMI",
      image: "blisscover.jpg",
      category: "Moisturizers",
    },
    {
      id: 17,
      title: "Midnight Nourishment Blend",
      description: "Our Midnight Nourishment Blend combines a powerful mix of botanical oils to provide deep hydration while you sleep. Argan oil, known for its moisturizing properties, works alongside rosehip and hemp seed oils to nourish your skin.",
      price: 1450,
      size: "30 ml",
      rating: 5,
      reviews: 85,
      emi: "₹667/Month | 0% EMI",
      image: "midnightcover.jpg",
      category: "Face Oil",
    },
    {
      id: 18,
      title: "Niacinamide Arbutin Fusion",
      description: "Niacinamide and Alpha Arbutin work together to even out skin tone and reduce the appearance of dark spots. This fusion helps to inhibit melanin production, leading to a more uniform complexion. Regular use can result in visibly brighter and clearer skin.",
      price: 1350,
      size: "30ml",
      rating: 5,
      reviews: 95,
      emi: "₹467/Month | 0% EMI",
      image: "niacover.jpg",
      category: "Serums",
    },
    {
      id: 19,
      title: "Oat Protein Shine Hair Serum",
      description: "Discover the power of natural ingredients with our OAT PROTEIN SHINE Hair Serum. Nourish, hydrate, and strengthen your hair while reducing frizz and adding shine. Experience healthier, more manageable locks today.",
      price: 650,
      size: "30ml",
      rating: 5,
      reviews: 80,
      emi: "₹400/Month | 0% EMI",
      image: "oatcover.jpg",
      category: "Serum",
    },
    {
      id: 20,
      title: "Sunshine Nourishing Lotion",
      description: "This lightweight formula is suitable for all skin types, including sensitive skin. Jojoba and grapeseed oils closely mimic the skin's natural sebum, ensuring deep moisturization without clogging pores. Sodium PCA and panthenol work to attract and retain moisture.",
      price: 2150,
      size: "100ml",

      price2: 1500,
      size2: "50ml",
      rating: 5,
      reviews: 120,
      emi: "₹700/Month | 0% EMI",
      image: "sunshinecover.jpg",
      category: "Moisturizers",
    },
    {
      id: 21,
      title: "Smooth Essence Conditioner",
      description: "Our Smooth Essence Conditioner combines the power of nature with scientific innovation to give your hair the care it deserves. Infused with rose hydrosol and natural oils, this conditioner helps restore moisture, enhance shine, and promote overall hair health.",
      price: 1450,
      size: "100ml",
      rating: 5,
      reviews: 95,
      emi: "₹767/Month | 0% EMI",
      image: "smoothcover.jpg",
      category: "Conditioner",
    },
    
   
  ];
  
  
  
  

  const filteredProducts = selectedCategory 
  ? allProducts.filter(product => product.category === selectedCategory) 
  : allProducts.filter(product => product.category === defaultCleanserCategory); 

  return (
    <section className="products-section">
    
    <h2>
        {selectedCategory ? `Products for ${selectedCategory}` : "Cleansing Products"}
      </h2>
      <div className="products-grid">
        {filteredProducts.slice(0, visibleProducts).map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      {visibleProducts < filteredProducts.length && (
        <div className="see-more-container">
          <button className="see-more-btn" onClick={showMoreProducts}>
            See More
          </button>
        </div>
      )}
    </section>
  );
};

export default Product;
