








import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../Review2/Review2.css';

const Review2 = () => {
  const topReviews = [
    {
      name: "Shruti Pandey (Dehradun)",
      image: "/images/product.png",
      text: "The Night Bliss Restorative Cream from 3ESkinRoute has truly changed my nighttime routine. It is rich and luxurious but absorbs beautifully without feeling greasy. I wake up with my skin feeling soft, hydrated, and rejuvenated. It’s become an essential part of my skincare regimen!",
    },
    {
      name: "Aarti (Delhi)",
      image: "/images/product.png",
      text: "I struggled with hyperpigmentation for years, but since I started using 3ESkinRoute’s products, I’ve seen incredible improvements. Their 3C Serum has helped fade my dark spots significantly, and my skin tone looks so much more even. I finally feel confident in my skin!",
    },
    {
      name: "Pooja (Delhi)",
      image: "/images/product.png",
      text: "I’ve been using 3ESkinRoute products for the past three months, and my skin has never looked better. The natural ingredients are gentle yet effective, and the results speak for themselves—my skin feels hydrated, smooth, and glowing. Thank you, 3ESkinRoute, for creating such an amazing skincare line.",
    },
    {
      name: "Anjali (Lucknow)",
      image: "/images/product.png",
      text: "I have extremely sensitive skin, and finding the right products has always been a challenge. 3ESkinRoute has been a game changer. Their Oat Bloom Moisturizer soothes my skin without any irritation. I also appreciate that they always take follow ups and guide accordingly.",
    },
    {
      name: "Aparna (Jalandhar)",
      image: "/images/product.png",
      text: "I love AHA BHA,PHA scrub ..it’s the best scrub I’ve ever tried. The exfoliation is perfect—not too harsh, but it leaves my skin feeling soft and fresh. Plus, I’ve also noticed that my skin feels more moisturized after each use. I’m definitely a loyal customer now.",
    },
    {
      name: "Nikita (Gurugram)",
      image: "/images/product.png",
      text: "As someone who’s always on the go, I need skincare products that are easy to use but deliver great results. 3ESkinRoute’s range does exactly that. Their cleansing balm and moisturizer have become my go-to routine, leaving my skin feeling clean and refreshed without stripping it of moisture. It’s a lifesaver for my busy lifestyle.",
    },
    {
      name: "Aparna Dayal (Varanasi)",
      image: "/images/product.png",
      text: "I’ve struggled with acne for years, and after my personalized skin consultation with Silky,I finally found products that work for me! The consultation was so knowledgeable and tailored the recommendations based on my skin’s needs. The acne control serum and hydrating face cream have transformed my skin—I feel more confident and radiant.",
    },
    {
      name: "Naina Singh (Lucknow)",
      image: "/images/product.png",
      text: "What I love most about 3ESkinRoute is their holistic approach to skincare. It’s not just about products—they consider your entire lifestyle, including diet and self-care. After my consultation, they recommended products that complemented a healthier lifestyle, and I’ve seen dramatic changes. My skin feels healthier, and I feel more balanced overall",
    },
    {
      name: "Aarti Srivastava (Chandigarh)",
      image: "/images/product.png",
      text: "I love their holistic approach that helped me understand how things like stress and diet impact my skin. It was the broader guidance on well-being that truly made the difference. My skin really is more clear than ever before.",
    },
    {
      name: "Naaz Fatima (Delhi)",
      image: "/images/product.png",
      text: "The holistic approach at 3ESkinRoute is what sets them apart. It’s not just about fixing skin problems but understanding the root causes. With their guidance on both skincare and lifestyle changes, like drinking more herbal teas and after using their cleansing balm, I feel more energized and my skin has never looked better.",
    },
    {
      name: "Kavita Tiwari (Varanasi)",
      image: "/images/product.png",
      text: "At 3ESkinRoute, I felt like they truly understood the connection between inner health and outer beauty. Their holistic recommendations included healthy eating habits and mindfulness practices, along with their amazing skincare products. This approach has brought me so much more than clear skin.",
    },
    {
      name: "Komal (Delhi)",
      image: "/images/product.png",
      text: "I was so impressed by 3ESkinRoute’s holistic approach. They looked beyond just skincare routines and really focused on how my lifestyle could impact my skin. Their advice, combined with their natural, soothing products, has improved my skin and overall wellness. It’s a refreshing change from other brands.",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reviewsPerSlide, setReviewsPerSlide] = useState(3); // Default to 3 reviews per slide

  useEffect(() => {
    const updateReviewsPerSlide = () => {
      if (window.innerWidth <= 768) {
        setReviewsPerSlide(1); // Show 1 review per slide on mobile
      } else {
        setReviewsPerSlide(3); // Show 3 reviews per slide on larger screens
      }
    };

    updateReviewsPerSlide(); // Call on component mount
    window.addEventListener('resize', updateReviewsPerSlide); // Listen for screen resizing

    return () => window.removeEventListener('resize', updateReviewsPerSlide); // Cleanup event listener on unmount
  }, []);

  const totalReviews = topReviews.length;
  const maxIndex = Math.ceil(totalReviews / reviewsPerSlide) - 1;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === maxIndex ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? maxIndex : prevIndex - 1));
  };

  const start = currentIndex * reviewsPerSlide;
  const end = start + reviewsPerSlide;
  const currentReviews = topReviews.slice(start, end);

  if (currentReviews.length < reviewsPerSlide) {
    currentReviews.push(...topReviews.slice(0, reviewsPerSlide - currentReviews.length));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 9000);

    return () => clearInterval(interval);
  }, [maxIndex]);

  // Swipeable handlers to enable swipe/drag functionality
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // This enables dragging with mouse as well
  });

  return (
    <>
      <h1 className="review-head">As Our Customer Says!</h1>
      <div className="review2-container">
        <div className="slider-wrapper" {...swipeHandlers}> {/* Adding swipe functionality */}
          <div className="slider">
            {currentReviews.map((review, index) => (
              <div key={start + index} className="review-card2">
                <div className="f-s">
                  <h4>{review.name}</h4>
                  <p>{review.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="dots-container">
          {Array.from({ length: maxIndex + 1 }).map((_, idx) => (
            <span
              key={idx}
              className={`dot ${currentIndex === idx ? 'active' : ''}`}
              onClick={() => setCurrentIndex(idx)}
            ></span>
          ))}
        </div>
      </div>
    </>
  );
};

export default Review2;
