import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../About/About.css";
import Story from "./Story";
import Icard from "../Icard/Icard";
import Brands from "../Brands/Brands";
import Icon from "./Icon";
import Divider from "../Divider/Divider";
import Swap from "../Swap/Swap";

const About = () => {
  const navigate = useNavigate(); // Use useNavigate hook for navigation
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const cards = [
    {
      id: 1,
      category: "Lab Stories",
      title: "AQUAXYL™",
      description:
        "AQUAXYL™ is a patented blend that enhances the skin's hydration flow through its unique mechanism of action identified by cosmetogenomics.",
      image: "n1.jpg",
      content: {
        Heading: "AQUAXYL™: A Game-Changer in Skincare Hydration",
        Description: `AQUAXYL™ is a patented blend that not only enhances the skin's hydration flow but also regulates water circulation and reserves while providing an “anti-dehydration shield.” By boosting the synthesis of essential lipids and proteins in the corneous layer, it strengthens and makes the skin more resilient.AQUAXYL™ meets the standards of both ECOCERT and NATRUE labels, ensuring its commitment to natural and sustainable ingredients. `,
        Key_Benefits: [
          "Enhances Hydration Flow: AQUAXYL™ improves the skin's ability to retain moisture, ensuring lasting hydration and a plump appearance.",
          "Regulates Water Circulation: It helps balance the water levels in the skin, preventing dehydration and promoting a healthier complexion.",
          "Anti-Dehydration Shield: This unique mechanism protects against environmental stressors that can lead to moisture loss.",
          "Strengthens Skin Resilience: By boosting essential lipids and proteins, AQUAXYL™ fortifies the skin barrier, making it more resilient against external aggressors."
        ],
        Unique_Features: `This innovative ingredient is designed to meet the highest standards of efficacy and safety. With its unique mechanism of action identified by cosmetogenomics, AQUAXYL™ works on multiple levels to ensure optimal hydration and skin health.`,
        Science_Behind: `AQUAXYL™ is formulated to reinforce the skin's natural barrier, making it more resistant to external aggressors. This blend is compliant with ECOCERT and NATRUE labels, ensuring a commitment to natural and sustainable ingredients.`,
        Why_Need: `If your skin craves hydration and protection, AQUAXYL™ is the solution you've been looking for. It simplifies your skincare routine while delivering potent results.`,
        Products_Featuring: `Look for products that incorporate AQUAXYL™ to experience its full benefits. These formulations are designed to enhance your skin's hydration levels while promoting a healthier appearance.`,
        Disclaimer: `The opinions expressed in this journal are those of the authors and are for informational purposes only and not medical advice. Further, they do not reflect the opinions or views of Aminu Wellness Pvt Ltd or any of its directors. Any content provided by the author(s) is of their opinion and is not intended to malign any religion, ethnic group, club, organization, company, individual, or anyone, or anything.`
      }
    },
    {
      id: 2,
      category: "Lab Stories",
      title: "Copper Tripeptide (GHK-Cu)",
      description:
        "Copper Tripeptide (GHK-Cu) is a potent peptide known for its remarkable skin benefits, including boosting collagen production, accelerating wound healing, and enhancing skin elasticity.",
      image: "n2.jpg",
      content: {
        Heading: "Copper Tripeptide (GHK-Cu): The Key to Youthful Skin",
        Description: `Copper Tripeptide (GHK-Cu) is a naturally occurring compound made up of three amino acids—glycine, histidine, and lysine—bound to copper. This powerful peptide plays a crucial role in promoting skin health by stimulating collagen production and enhancing elasticity.`,
        Key_Benefits: [
          "Collagen Boosting: Copper Tripeptide encourages collagen production, essential for maintaining skin structure and firmness. The result? A smoother, more youthful appearance.",
          "Wound Healing: It accelerates the skin’s natural healing process, reducing the appearance of scars and supporting overall skin repair.",
          "Antioxidant Properties: This peptide offers potent antioxidant protection, defending your skin against free radicals and oxidative stress, contributing to a healthier complexion.",
          "Hydration: By enhancing the skin's barrier function, Copper Tripeptide helps retain moisture, keeping your skin hydrated and plump.",
          "Anti-Inflammatory Effects: It soothes irritation and redness, making it ideal for sensitive skin types.",
          "Skin Regeneration: Copper Tripeptide promotes cell turnover, revealing fresher, more radiant skin over time."
        ],
       
      }
    },
    {
      id: 3,
      category: "Lab Stories",
      title: "Bio Retinol-Revinage®",
      description:
        "Bio Retinol-Revinage® is a plant-based CO2 supercritical extract that mimics synthetic retinoids, offering anti-aging benefits without irritation. It reduces wrinkles, evens skin tone, and restores moisture, making it a versatile skincare ingredient.",
      image: "n3.jpg",
      content: {
        Heading: "Bio Retinol-Revinage®: Nature's Alternative to Retinoids",
        Description: `Bio Retinol-Revinage® is a CO2 supercritical plant-based extract acting similar to synthetic retinoids, promotes the treatment of aging skin without adverse effects. It has antioxidant action and soothing properties. It visibly reduces wrinkles and expression lines and evens the skin tone. It also restores chapped lips. `,
        Key_Benefits: [
          "Gentle Anti-Aging: Effectively replicates the benefits of synthetic retinoids while significantly reducing the risk of irritation”",
          "Wrinkle Reduction: Helps visibly reduce the appearance of fine lines and wrinkles, promoting a smoother skin texture. ",
          "Even Skin Tone: Promotes a more uniform complexion by addressing issues such as dark spots and hyperpigmentation. ",
          "Antioxidant Protection: Provides powerful antioxidant properties that help protect the skin from free radical damage and environmental stressors. ",
          "Soothing Properties: Calms and soothes irritated skin, reducing redness and sensitivity. ",
          "Hydration:  Restores moisture to dry or chapped lips and enhances overall skin hydration. ",
          "Skin Renewal: Encourages skin cell turnover, promoting a fresher and more youthful appearance."
        ],
       
      }
    },
    {
      id: 4,
      category: "Lab Stories",
      title: "Potassium Azeloyl Diglycinate",
      description:
        "Potassium Azeloyl Diglycinate is an advanced form of azelaic acid, enhanced with glycine for added hydration benefits. It offers anti-inflammatory, brightening, and anti-acne properties, making it ideal for sensitive and acne-prone skin.",
      image: "n4.jpg",
      content: {
        Heading: "Potassium Azeloyl Diglycinate",
        Description: `Potassium Azeloyl Diglycinate represents an innovative evolution of traditional azelaic acid, infused with the moisturizing benefits of glycine. Azelaic acid has long been praised for its exceptional anti-inflammatory properties, making it a popular choice for reducing redness and irritation. Additionally, it effectively brightens the complexion and targets acne, promoting clearer and healthier skin.  `,
        Key_Benefits: [
          "Anti-Inflammatory Properties:  Effectively reduces redness and irritation, making it suitable for sensitive and reactive skin. ",
          "Skin Brightening: Helps to even out skin tone and diminish dark spots, promoting a more radiant complexion. ",
          "Anti-Acne Benefits: Aids in preventing and treating acne breakouts by reducing inflammation and unclogging pores. ",
          "Enhanced Hydration: The glycine component provides moisture and improves skin hydration, resulting in a plump and healthy appearance. ",
          "Gentle on Skin: Offers the benefits of azelaic acid in a milder form, making it suitable for all skin types, including sensitive skin. "
        ],
       
      }
    },
    {
      id: 5,
      category: "Lab Stories",
      title: "Hyaluronic Acid",
      description:
        "Hyaluronic Acid is a powerful humectant that can hold up to 1,000 times its weight in water, making it essential for maintaining skin hydration and elasticity. It's suitable for all skin types and helps reduce the appearance of fine lines and wrinkles.",
      image: "n5.jpeg",
      content: {
        Heading: "Hyaluronic Acid: The Ultimate Hydration Booster",
        Description: `Hyaluronic Acid (HA) is a naturally occurring substance in the skin, known for its incredible ability to retain moisture. As we age, our natural HA levels diminish, leading to dryness and the appearance of fine lines. Incorporating HA into your skincare routine can help combat these effects by providing deep hydration and improving skin elasticity.`,
        Key_Benefits: [
          "Intense Hydration: HA can hold up to 1,000 times its weight in water, delivering immediate and long-lasting hydration to the skin.",
          "Improves Elasticity: Regular use of Hyaluronic Acid helps maintain skin elasticity and firmness, promoting a youthful appearance.",
          "Reduces Fine Lines: By plumping the skin with moisture, HA can visibly reduce the appearance of fine lines and wrinkles.",
          "Soothes Irritation: Hyaluronic Acid is gentle on the skin and can help calm irritation and redness, making it suitable for all skin types.",
          "Supports Skin Repair: It aids in the skin's natural repair process, promoting a healthy and rejuvenated complexion."
        ],
        Why_Need: `Hyaluronic Acid is a must-have in any skincare routine, especially for those experiencing dryness, dullness, or signs of aging. Its ability to hydrate and improve skin texture makes it a staple ingredient in modern formulations.`,
        Products_Featuring: `Look for serums, moisturizers, and masks that feature Hyaluronic Acid for an instant boost of hydration and overall skin health.`,
        Disclaimer: `This information is provided for educational purposes only and should not be considered medical advice. Always consult a professional for personalized skincare recommendations.`
      }
    }
  ];
  

  const steps = [
    {
      id: 1,
      title: "Assess",
      description:
        "Take our comprehensive skin quiz to help us understand your unique needs. ",
      image: "step1.jpg",
      video: "",
    },
    {
      id: 2,
      title: "Customize",
      description:
        " Our experts craft a personalized skincare routine just for you.You will have follow up sessions where we guide you for internal health management for well balanced skin.",
      image: "",
      video: "step2.mp4",
    },
    {
      id: 3,
      title: "Deliver",
      description:
        " Receive your eco-friendly package of tailored skincare products. ",
      image: "step3.jpg",
      video: "",
    },
    {
      id: 4,
      title: "Glow",
      description:
        "Follow your simple routine and watch your skin transform naturally. ",
      image: "step4.jpg",
      video: "",
    },
  ];

  const handleReadMore = (card) => {
    setSelectedCard(card);
    navigate(`/card/${card.id}`, { state: { cardData: card } }); // Use navigate to route
  };

  useEffect(() => {
    // Set a timeout to open the modal after 1 minute (60000 ms)
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 10000); // 1 minute delay

    // Cleanup the timeout if the component unmounts or if the timer changes
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run once on mount

  const closeModal = () => {
    setIsOpen(false);
  };
  const truncateDescription = (description) => {
    const words = description.split(' ');
    return words.length > 5 ? words.slice(0, 5).join(' ') + '...' : description;
  };
  return (
    <>
      <div>{isOpen && <Icard onClose={closeModal} />}</div>

      <div className="post-a">
        <img src="about.jpeg" alt="Poster" className="post-a-image" />
      </div>

      <div className="plan-section">
        <div className="plan premium" data-aos="fade-down">
          <h2>We are all about Skin health!</h2>
          <p className="plan-p">Discover The Passion Behind
</p>
        </div>

        <div className="plan2 advanced" data-aos="fade-up">
          <p className="bp">
            In a world overwhelmed by one-size-fits-all skincare solutions, 3ESkinRoute was born out of a simple yet powerful belief: that every
            individual’s skin is unique and deserves a tailored approach. Our
            journey began with a deep-rooted passion for plant-based skincare
            and a vision to create products that not only cater to different
            skin types but also honor the individuality of each person. At the
            heart of 3ESkinRoute lies the philosophy of "3E"—Educate, Endure,
            Embrace.
          </p>
          <p className="bp">
            3ESkinRoute is more than just skincare; it’s a journey of
            self-discovery and self-care. We invite you to join us on this path,
            where you’ll learn to educate yourself about your skin, endure the
            challenges of your skincare journey, and embrace the natural beauty
            that is uniquely yours.
          </p>
        </div>
      </div>

      {/*-
      <h1 className="post-a-heading">Brand Story</h1>

      <div className="post-a-content">
        <div className="left-a">
          <h2>We are all about Skin Health!</h2>
          <h4>Discover the Passion Behind</h4>
        </div>
        <div className="right-a">
          <p>
            At 3E SKINROUTE, we're on a mission to help people take Plant Based
            Skincare products as lifestyle change rather than just beauty
            solutions. We believe that everyone deserves a skincare routine
            that's as unique as they are. By combining the power of nature with
            cutting-edge science, we create personalized, plant-based solutions
            that address your specific skin concerns.
          </p>
          <p>
            Our Expert formulators work tirelessly to source the most effective,
            sustainable ingredients from around the world. We're committed to
            transparency, sustainability, and results you can see and feel. Join
            us on the path to healthier, happier skin – naturally.
          </p>
        </div>
      </div>
      -*/}
      {/*-
      <div className="l-story-container">
        <img src="logo.png" alt="Story" className="l-story-img" />
        <div className="l-s">
          <h1>Radiance in Every Drop: The Tale Behind Our Emblem</h1>
          <h5>
            Our logo is more than just a symbol—it embodies the journey to
            radiant, healthy skin. The elegant leaf design reflects our
            deep-rooted commitment to nature, representing the power of clean,
            plant-based ingredients. At its center, the graceful droplet
            signifies hydration, the key to glowing skin, while the gentle
            curves mirror the simplicity and purity found in each of our
            formulations. The pastel tones evoke calmness and balance, much like
            the peace we aim to bring to your skincare routine. With our logo,
            we invite you to embrace the beauty of nature and the confidence
            that comes with truly nourished skin.
          </h5>
        </div>
      </div>
      -*/}

      <Icon />
      {/*-
      <section className="logo-s">
      <div className="l-ll">
        <h1>Radiance in Every Drop: The Tale Behind Our Emblem</h1>
        <h5>Our logo is more than just a symbol—it embodies the journey to
            radiant, healthy skin. The elegant leaf design reflects our
            deep-rooted commitment to nature, representing the power of clean,
            plant-based ingredients. At its center, the graceful droplet
            signifies hydration, the key to glowing skin, while the gentle
            curves mirror the simplicity and purity found in each of our
            formulations. The pastel tones evoke calmness and balance, much like
            the peace we aim to bring to your skincare routine. With our logo,
            we invite you to embrace the beauty of nature and the confidence
            that comes with truly nourished skin.</h5>
      </div>
      <div className="r-ll">
        <img src="ll.png" alt="Logo" className="l-rr" />
      </div>
    </section>
-*/}

<section className="o-story">
        <div className="content">
          <h1 className="h-st">Company's Journey</h1>
          <p>
            3ESkinRoute was born from a vision to revolutionize skincare
            through the power of nature. Founded by <strong> Silky Chopra, a renowned
            Cosmetic Formulator and Consultant</strong>, our journey began with a simple
            yet profound belief: plant-based ingredients can transform not just
            your skin, but your entire approach to self-care.
          </p>
          <p>
            Since our inception, we've been on a mission to create customized
            skincare solutions that harness the potency of botanical extracts.
            Our growth has been organic, much like the ingredients we cherish,
            fueled by a passion for innovation and a commitment to skin health.
            Our ultimate goal is to foster a community of conscious consumers
            who understand and appreciate the value of plant-based ingredients
            in their daily skincare routine.
          </p>
        </div>
      </section>
     
      <Story />
     

      <section className="n-incre">
  <h2>Our Nature's Best in Bottle</h2>
  <div className="grid-container">
    {cards.map((card) => (
      <div key={card.id} className="n-incre-card">
        <img src={card.image} alt={card.title} className="card-image" />
        <div className="card-content">
          <h3>{card.title}</h3>
          <p>{truncateDescription(card.description)}</p>
          <button
            className="read-more4"
            onClick={() => handleReadMore(card)}
          >
            Read More
          </button>
        </div>
      </div>
    ))}
  </div>
</section>
      {/*-
      <section className="step-a">
        <h2>How It Works</h2>
        <div className="step-container">
          {steps.map((step, index) => (
            <div key={step.id} className="step-card">
              <div className="step-media">
                {step.video && (
                  <video
                    className={`step-video ${
                      index % 2 === 0 ? "show" : "hide"
                    }`}
                    src={step.video}
                    autoPlay
                    loop
                    muted
                  />
                )}
                {step.image && (
                  <img
                    className={`step-image ${
                      index % 2 === 0 ? "hide" : "show"
                    }`}
                    src={step.image}
                    alt={step.title}
                  />
                )}
              </div>
              <div className="step-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="arrow-animation">
                  <div className="arrow"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
-*/}
      <Divider />
      <Brands />
    </>
  );
};

export default About;
