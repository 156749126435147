// src/components/Ingredents/Ingredents.js
import React from "react";
import "../Ingredents/Ingredents.css";
import OurIngredients from "./OurIncredients";
import Incre from "./Incre";
import Move from "../Move/Move";
import Clip from "../Clip/Clip";

const Ingredents = () => {
  return (
    <>
      <Move />
      <section className="incre-p">
        <div className="left-incre">
          <h5 className="incre-h5">Pure Ingredients, Exceptional Quality</h5>
          <h2 className="incre-h">Nature's Best In Every Bottle</h2>
          <p className="incre-p">Rich in Nutrients & Highly Effective</p>
        </div>
        <div className="right-incre">
          <img src="/ingredient1.jpg" alt="Description" />
        </div>
      </section>
      <p className="text-bottle">
        We put as many ingredients in one bottle as much it takes to heal the
        concern holistically and help improve overall health of the skin. We
        believe that one bottle should solve your primary & secondary concerns
        and help keep your skin healthy and nourished. Thus can keep you regime
        minimalist and quick. Every day is different for your skin like the rest
        of our physical, mental and emotional self. Our self-care regime should
        reflect this natural flux.
      </p>

      <section className="i-count">
        <div className="count-item">
          <h3>Sustainably Sourced - Globally Inspired</h3>
          <p>
            Spanning over 15+ nations, our global search leads us to exceptional
            components, valuable plant derivatives, enchanting essences, and
            distinctive molecular structures. We prioritize potency,
            harmlessness, and eco-conscious practices when choosing ingredients.
            This meticulous selection process guarantees that each formulation
            enhances your skin's appearance and provides a pleasant sensation.
          </p>
        </div>
        <div className="count-item">
          <h3>55+ Power Packed Actives </h3>
          <p>
            Our products boast over 55+ high-quality components designed to
            address multiple skin concerns simultaneously. We've carefully
            chosen a diverse mix of potent nutrients to create formulations that
            cater to various skin needs. Our range incorporates caring elements,
            peptide complexes, essential vitamins, plant-derived extracts,
            invigorating minerals, and crucial amino acids. These ingredients
            are further enhanced through cutting-edge scientific methods to
            deliver optimal results for your skin.
          </p>
        </div>
        <div className="count-item">
          <h3>Nature’s Best: Our Ingredients</h3>
          <p>
            Over 250+ distinct primary components form the foundation of our
            products, encompassing long-lasting moisturizers, nutrient-rich seed
            extracts, invigorating aromatic essences, and floral distillates,
            among others. We continuously enhance and refine our offerings with
            cutting-edge elements, delving into skin science to craft suitable
            formulations that promote a sense of wellness.
          </p>
        </div>
      </section>

      <section className="incre-f">
        <h2 className="incre-f-heading">Our Featured Nutrients</h2>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">AQUAXYL™</h3>
          <div className="fullcc">
            <img
              src="/AQUAXYL™.jpg"
              alt="Feature"
              className="incre-f-feature-img"
            />
            <div className="divt">
              <p className="incre-f-feature-description">
                AQUAXYL™ is a patented blend that enhances the skin's hydration
                flow through its unique mechanism of action identified by
                cosmetogenomics. It effectively regulates water circulation and
                reserves while providing an “anti-dehydration shield” by
                boosting the synthesis of essential lipids and proteins in the
                corneous layer. As a result, the restructured stratum corneum
                becomes stronger and more resilient. AQUAXYL™ meets the
                standards of both ECOCERT and NATRUE labels, ensuring its
                commitment to natural and sustainable ingredients.
              </p>
            </div>
          </div>
        </div>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">Biden Pilosa</h3>
          <div className="fullcc">
            <img src="/i2.webp" alt="Feature" className="incre-f-feature-img" />
            <div className="divt">
              <p className="incre-f-feature-description">
                BioRetinol is a super critical fluid apolar extract of Bidens
                pilosa. Rich phytochemical composition, standardized in phytol.
                It is with retinoids receptors activity, mimicking the same
                positive results that retinoids to fight against skin aging.
                Skin visibly younger, luminous and firm, reducing reduces
                wrinkles, improves skin elasticity and hydration.
              </p>
            </div>
          </div>
        </div>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">Copper Tripeptide-1(GHK-CU)</h3>
          <div className="fullcc">
            <img
              src="/Copper Tripeptide-1(GHK-CU).jpg"
              alt="Feature"
              className="incre-f-feature-img"
            />
            <div className="divt">
              <p className="incre-f-feature-description">
                Copper Tripeptide-1(GHK-CU) is a remarkable ingredient known for
                its skin-rejuvenating properties. It stimulates collagen
                production, promoting firmness and reducing the appearance of
                fine lines and wrinkles. This peptide also enhances skin healing
                and repair, making it ideal for post-acne or pigmented skin.
                Additionally, its antioxidant benefits help protect the skin
                from environmental stressors, supporting a healthier complexion.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Clip />
    </>
  );
};

export default Ingredents;
