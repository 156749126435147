
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Blog from './Components/Blog/Blog';
import BlogDetail from './Components/Blog/BlogDetail';
import About from './Components/About/About';
import Ingredents from './Components/Ingredents/Ingredents';
import CardDetails from './Components/About/CardDetails';
import Consultation from './Components/Contact/Consultaion'
import Shop from './Components/Shop/Shop';
import Chatbox from './Components/Chatbox/Chatbox';
import "aos/dist/aos.css";
import AOS from "aos";
import ShopDetails from './Components/Shop/Shopdetails';
import Tc from './Components/T&C/Tc';
import Disclaimer from './Components/Disclaimer/Disclaimer';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    // Initialize AOS animations
    AOS.init({
      duration: 1000,
    });

    // Disable right-click context menu
    const handleContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", handleContextMenu);

    // Disable specific keyboard shortcuts (F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U)
    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J')) || // Ctrl+Shift+I or Ctrl+Shift+J
        (event.ctrlKey && event.key === 'U') // Ctrl+U
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/shopdetails/:id" element={<ShopDetails />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/tc" element={<Tc />} />
        <Route path="/about" element={<About />} />
        <Route path="/card/:id" element={<CardDetails />} />
        <Route path="/ingredients" element={<Ingredents />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/shop" element={<Shop />} />
      </Routes>
      <Footer />
      <Chatbox />
    </Router>
  );
}

export default App;


